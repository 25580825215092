<template>
  <v-dialog v-model="visible" persistent max-width="500">
    <v-card class="card-border py-5">
      <v-card-text class="subtitle-1 text-center">{{ title }}</v-card-text>
      <v-card-text v-if="description">{{ description }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :loading="onLoading"
          color="primary"
          class="px-10"
          @click="onCancel"
          >Batal</v-btn
        >
        <v-spacer />
        <v-btn
          :loading="onLoading"
          color="error"
          class="px-10"
          @click="onSubmit"
          >Keluar</v-btn
        >
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Logout",
  props: {
    visible: { type: Boolean, required: true },
    title: { type: String, required: true },
    description: { type: String, required: false },
    onSubmit: { type: Function, required: true },
    onCancel: { type: Function, required: true },
    onLoading: { type: Boolean, required: false, default: false }
  }
};
</script>

<style>
.card-border {
  border-radius: 10px;
}
</style>
